import { sha256 } from 'js-sha256';

import _move from "../lib/move";
import * as ptr from "@pcloud/web-utilities/dist/lib/ptr";

import * as cjAffiliate from "@pcloud/web-utilities/dist/lib/cjAffiliate";
import * as SHAgancy from "@pcloud/web-utilities/dist/lib/6HAgancy";
import * as utils from "../lib/utils";

const _attachList = [
  {
    attach: self,
    list: [
      //tracking,
      "getPurchaseData",
      "initAnalytics",
      "initFacebookTracking",
      "initBingTracking",
      "setPromoParam",
      "getPromoParam",
      "sendAnalyticsEvent",
      "sendPassAnalyticsEvent",
      "sendAnalyticsEventFromStorage",
      "sendAnalyticsTag",
      "setFBTrackingForReferral",
      "initTracking",
      "saveToSessionstorage",
      "setTracking",
      "sha256",

      //CJ AFFILIATE
      "CJ_PAGE_TYPE_MAP",
      "CJ_PLANS_PREFIX",
      "saveCJCookie",
      "sendCJPageVisitTag",
      "sendCJConversionTag",
      "sendCJTokenOnLogin",

      //6H Agancy
      "get6HData",
      "set6HData",
      "send6HData"
    ]
  }
];

_move(utils, _attachList);
_move(ptr, _attachList);
_move({sha256: sha256}, _attachList);
_move(cjAffiliate, _attachList);
_move(SHAgancy, _attachList);

(() => {
  // mockup all tracking methods used and unused
  window.twq = function() {};
  window.pintrk = function() {};
  window.lintrk = function() {};
  
	if (!(utils.hrefSearch("product") && utils.hrefSearch("product").indexOf("pass-premium") !== -1)) {
		ptr.setTracking({ initialCall: true, forceTrack: false });
		cjAffiliate.saveCJCookie();
	}
})();
